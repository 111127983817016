export default {
    namespaced:true, 
    state: {
        dark:false,
    },
    mutations: {
        SET_DARK(state, value){
          state.dark = value
        },
    },
    actions: {
  
    }
  }