export default {
    namespaced:true, 
    state: {
        tipoCambio: {}
    },
    mutations: {
        setTipoCambio(state, value){
          state.tipoCambio = value;
        },
    },
    actions: {
  
    }
  }