import axios from "axios";
import store from "../store";

axios.defaults.baseURL = process.env.VUE_APP_URL_API;
axios.interceptors.request.use(
  (config) => {
    config.headers.common["X-Requested-With"] = "XMLHttpRequest";
    config.headers.common["Authorization"] =
      "Bearer " + store.state.login.token;
    return config;
  },
  (error) => {
    // Do something with request error

    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("dezainerp");
      localStorage.removeItem("cemedic");
      location.reload();
    }

    if (error.response.status == 403) {
      localStorage.removeItem("dezainerp");
      localStorage.removeItem("cemedic");
      location.reload();
    }

    return Promise.reject(error);
  }
);

export default axios;
