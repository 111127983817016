export default {
    namespaced:true, 
    state: {
        configuracion:{}
    },
    mutations: {
        SET_CONFIGURACION(state, value){
          state.configuracion = value
        },
    },
    actions: {
  
    }
  }