export default {
    namespaced:true, 
    state: {
        empresa:{},
        empresas:[],
    },
    mutations: {
        SET_EMPRESA(state, value){
          state.empresa = value
        },
        SET_EMPRESAS(state, value){
          state.empresas = value
        },
    },
    actions: {
  
    }
  }