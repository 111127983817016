export default {
    namespaced:true, 
    state: {
        guia:{}
    },
    mutations: {
        SET_GUIA(state, value){
          state.guia = value
        },
    },
    actions: {
  
    }
  }