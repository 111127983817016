export default {
    namespaced:true, 
    state: {
        token:null,
        usuario:{},
        mitimer:{
          intentos:3,
          tiempo:60
        }
    },
    mutations: {
        SET_TOKEN(state, value){
          state.token = value;
        },
        SET_USUARIO(state, value){
          state.usuario = value;
        },
        SET_MITIMER_INTENTOS(state, value){
          if(value >= 0){
            state.mitimer.intentos = value;
          }else{
            state.mitimer.intentos--;
          }
          
        },
        SET_MITIMER_TIEMPO(state, value){
          if(value >= 0){
            state.mitimer.tiempo = value;
          }else{
            state.mitimer.tiempo--;
          }
        }
    },
    actions: {
  
    }
  }